import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import swal from 'sweetalert'
import { navigate } from 'gatsby-link'

import { getInvitationDetailAPI, getProfile } from '../utils/api'
import SEO from '../components/seo'
import Loading from '../components/loading'

import homeActions from '../state/home/actions'

class CheckInPage extends React.Component {
  state = {
    locale: {},
    isLoading: true,
    invitation_id: 0,
    gotoHome: false,
  }

  componentDidMount() {
    this.onLoad()
  }

  onLoad = async () => {
    const resProfile = await getProfile()
    const locale = this.props.app.locale[this.props.app.lang]
    if (resProfile.data) {
      const params = queryString.parse(this.props.location.search)
      const locale = this.props.app.locale[this.props.app.lang]
      const invitation_id = params.id || 99
      const isLoading = invitation_id === 99
      if (isLoading) {
        this.setState({ locale, invitation_id, isLoading })
      } else {
        const { data } = await getInvitationDetailAPI(invitation_id)
        if (data) {
          //Is Owner
          if (resProfile.data.username === data.event.username) {
            if (data.status === 'checked-in') {
              await swal(locale.ERROR, locale.ERROR_ALREADY_CHECKIN, 'error')
              this.setState({
                isLoading: false,
                gotoHome: !this.state.gotoHome,
              })
            } else {
              this.setState({ locale, invitation_id, isLoading }, this.onLoaded)
            }
          } else {
            await swal(locale.ERROR, locale.ERROR_NOT_OWNER_EVENT, 'error')
            this.setState({ isLoading: false, gotoHome: !this.state.gotoHome })
          }
        } else {
          await swal(locale.ERROR, locale.ERROR_NOT_LOGIN, 'error')
          this.setState({ isLoading: false, gotoHome: !this.state.gotoHome })
        }
      }
    } else {
      await swal(locale.ERROR, locale.ERROR_NOT_LOGIN, 'error')
      this.setState({ isLoading: false, gotoHome: !this.state.gotoHome })
    }
  }

  onLoaded = async () => {
    const cf = await swal({
      className: 'swal-popup-confirm',
      title: this.state.locale.WARNING,
      text: this.state.locale.WARNING_CHECK_IN + '?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: this.state.locale.CANCEL,
          value: null,
          visible: true,
          className: 'swal-popup-btn-cancle',
          closeModal: true,
        },
        confirm: {
          text: this.state.locale.CHECK_IN,
          value: true,
          visible: true,
          className: 'swal-popup-btn-ok',
          closeModal: true,
        },
      },
    })
    if (cf) {
      this.onSubmit()
    } else {
      navigate('/')
    }
  }

  onSubmit = () =>
    this.props.onSubmit({
      id: this.state.invitation_id,
      onSuccess: this.onSubmitSuccess,
      onError: this.onSubmitError,
    })

  onSubmitSuccess = () => this.setState({ isLoading: false })
  onSubmitError = () => this.setState({ isLoading: false })

  render() {
    if (this.state.isLoading) {
      return <Loading />
    }

    if (this.state.gotoHome) {
      navigate('/')
    }

    return (
      <React.Fragment>
        <SEO keywords={[`invitree`]} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    app: state.app,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      onSubmit: homeActions.onCheckInPostData,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckInPage)
